<template>
  <div class="login-wrapper">
    <div class="login">
      <el-row class="login-content">
        <el-col :span="12" class="left">
          <el-carousel trigger="click" class="left-content" height="388px">
            <el-carousel-item v-for="item in 4" :key="item" class="carousel-item">
              <img src="../assets/img/login_move.png">
            </el-carousel-item>
          </el-carousel>
        </el-col>
        <el-col :span="12" class="right">
          <div class="content right-content">
            <div class="logo" ref="logoF">
              <img :src="companyInfo.logoCompany" alt="logo" width="240" ref="logoImg" @load="imgload">
            </div>
            <el-form :model="user" status-icon :rules="rules" ref="login" class="demo-ruleForm" style="{'width':'270px'}">
              <el-form-item prop="username" class="username">
                <el-input v-model="user.username" prefix-icon="el-icon-user" placeholder="请输入用户名"></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input type="password" v-model="user.password" prefix-icon="el-icon-lock" placeholder="请输入密码" @keyup.enter.native='login' ></el-input>
              </el-form-item>
              <el-checkbox v-model="checked">
                <el-button type="text" @click="dialogTableVisible = true" class="read">我已阅读并同意《用户协议》及相关服务政策</el-button>
              </el-checkbox>
              <el-form-item>
                <el-button type="primary" @click="login()" class="button" round>
                  <span>开始工作</span>
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-dialog title="用户隐私权政策1.0" :visible.sync="dialogTableVisible" append-to-body>
      <iframe src="https://www.86xc.net/UserAgreement/UserPrivacyPolicy.html" style="width:100%" height="500" >
      </iframe>
    </el-dialog>
    <div class="bottom-wrapper">
      <bottom v-loading="loading1" :wxMerchantName="companyInfo"/>
    </div>
  </div>
</template>

<script>
  import bottom from '../components/bottom/index.vue'
  export default {
    data() {
      return {
        companyInfo: {},
        checked: true,
        dialogTableVisible: false,
        loading:false,
        loading1:false,
        rules: {//验证规则
          username: [
            {required: true,message: '用户名不能为空',trigger: 'blur',},
            {min: 1,max: 20,message: '用户名长度为 1 到 20 个字符',trigger: 'blur',}
          ],
          password: [
            {required: true,message: '密码不能为空',trigger: 'blur',},
            {min: 1,max: 20,message: '密码长度为 1 到 20 个字符',trigger: 'blur',}
          ],
        },
        user: {
          username: '',
          password: '',
        },
        showUserName:false,
        showPassword:false,
        MenuData: '',
        co:'',
        authoritySwitch: '',
      }
    },
    watch:{
      $route(to,from){
        this.co=to.params.co
        sessionStorage.setItem('co',this.co)
      },
    },
    components: {
      bottom
    },
    created(){
      this.co = this.$router.history.current.params.co.toLowerCase()
      sessionStorage.setItem('co',this.co)
      console.log(this.$router.history)
      this.coVerification(this.co)
    },
    mounted() {
      this._companyInfo()
    },
    methods:{
      imgload(){//监听图片是否加载完成
        if(this.$refs.logoImg.height > 160){
          this.$refs.logoImg.width = 180
          this.$refs.logoF.style.paddingBottom = 0
        }
      },
      //公司验证
      coVerification(co){
        this.loading = true;
        this.$axios({
          method: 'get',
          url: '/common1/getCo',
          params: {
            co: co,
            ipAddress: window.returnCitySN?window.returnCitySN.cip:'127.0.0.1'
            // ipAddress: 1
          }
        }).then(res => {
          if(res.data.result != 3){
            this.$confirm(`版本错误,是否跳转官网`).then(() => {
              window.location.assign("http://www.fangzhizun.com")
            }).catch(err => {
              this.$router.push('/notfind404')
            });
          }
          //第三方跳过登录
          let username = this.$route.query.username
          let password = this.$route.query.password
          console.log(username + "    " + password)
          if (username != undefined && password != undefined) {
            this.user.username = username
            this.user.password = password
            this.login(0)
          }
          this.loading = false
        }).catch(err => {
          this.$confirm(`${err.response.data.msg},是否跳转官网`).then(() => {
            window.location.assign("http://www.fangzhizun.com")
          }).catch(err => {
            this.$router.push('/notfind404')
          });

        })
      },
      log() {//获取日志信息
        let obj = {}
        //获取分辨率
        obj.resolvingPower = `${window.screen.width} x ${window.screen.height}`
        //获取ip地址、地区
        obj.ipAddress = window.returnCitySN?window.returnCitySN.cip:'127.0.0.1'
        obj.region = window.returnCitySN?window.returnCitySN.cname:'深圳市'
        obj.co = this.co
        obj.suName = this.user.username
        obj.suPassword = this.user.password
        obj.userType = 'pc用户'
        obj.loginTime = new Date().getTime()
        return obj
      },
      // 网页初始请求
      _companyInfo() {
        this.loading1 = true
        this.$axios({
          methods: 'get',
          url: '/common1/open/companys/companyInfo',
          params: {
            'co': this.co
          }
        }).then(res => {
          this.loading1 = false
          this.companyInfo = res.data.result
        }).catch(err => {
          this.$set(this.companyInfo,'logoCompany','../../src/assets/images/fangzhizun1_login.png')//报错默认图片
        })
      },
      login(type){
        if(!this.timer){
          if(this.submitForm(['login'],this)){
            this.loading=true
            this.$axios({
              method: 'post',
              url: '/common1/login',
              data: this.log()
            }).then(res => {
              this.loading=false
              sessionStorage.setItem('way', 1)
              sessionStorage.setItem('token',res.data.result.token)
              sessionStorage.setItem('companyName', res.data.result.companyName)
              sessionStorage.setItem('companyType', res.data.result.companyType)
              sessionStorage.setItem('userName', res.data.result.userName)
              sessionStorage.setItem('companyRentProvince', res.data.result.companyRentProvince)
              sessionStorage.setItem('companyRentCity', res.data.result.companyRentCity)
              sessionStorage.setItem('account', this.user.username)
              sessionStorage.setItem('password', this.user.password)
              sessionStorage.setItem('spId',1)
              this.getCurrentUserList()
              this.$router.push('/welcome')
            }).catch(err => {
              this.loading=false
              this.isError(err,this)
            })
          }
          this.timer = setTimeout(()=>{this.timer=''}, 2000);
        }
      },
      getCurrentUserList() {//查询登录用户
        this.$axios({
          url: "/common1/users/currentUser",
          method: "get"
        }).then(res => {
            sessionStorage.setItem("userCoding", res.data.result.userCoding); //登录人id
            sessionStorage.setItem("storefrontId",res.data.result.storefrontId); //登录人店面id
            sessionStorage.setItem("departmentId",res.data.result.departmentId); //登录人部门id
            sessionStorage.setItem("staffName", res.data.result.suStaffName); //登录人名称
        }).catch(err => {
          this.isError(err,this)
        })
      }
    }
  };
</script>



<style lang="scss">
  /* 修复input 背景不协调 和光标变色 */
  /* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

  html,body{
    padding: 0;
    margin: 0;
    height: 100% !important;
  }

  $bg:#283443;
  $light_gray:#fff;
  $cursor: #fff;

  @supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
    .login-container .el-input input {
      color: $cursor;
    }
  }

  /* reset element-ui css */
  .login-container {
    .el-input {
      display: inline-block;
      height: 47px;
      width: 90%;
      input {
        background: transparent;
        border: 0px;
        -webkit-appearance: none;
        border-radius: 0px;
        padding: 12px 5px 12px 15px;
        color: $light_gray;
        height: 47px;
        caret-color: $cursor;

        &:-webkit-autofill {
          box-shadow: 0 0 0px 1000px $bg inset !important;
          -webkit-text-fill-color: $cursor !important;
        }
      }
    }

    .login-form{
      .el-form-item {
        border: 1px solid rgba(255, 0, 0, 0.1);
        background: rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        margin: 30px auto !important;
        color: #454545;
        position: relative;
        .ver{
          margin: 0;
          color: #f00;
          position: absolute;
          bottom: -30px;
        }
        padding: 0;
        .el-form-item__content{
          position: relative;
        }
        .el-form-item__error{
          line-height: 1;
          font-size: 10px;
          position: absolute;
          padding-top: 5px;
          top: 100%;
          left: 65px;
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  a{
    text-decoration: none;
    color: rgb(166, 166, 166);
    line-height: 12px;
    font-size: 12px;
  }
  .login-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2d3a4b;
    min-height: 700px;
    max-width: 3000px;
    min-width: 1200px;
    .login{
      position:absolute;
      top:45%;
      left: 50%;
      -webkit-transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
      transform: translate(-50%,-50%);
      width: 55%;
      height: 55%;
      // height: 50%;
      max-width: 100%;
      margin: 0 auto;
      // margin: 0 auto;
      overflow: hidden;
      background: white;
      border-radius: 10px !important;
      // background-clip:content-box;
      // .login-content{
      //   border-radius: 10px !important;
      // }
      // width: 416px;
      // height: 216px;
      .left-content{
        margin: 0 auto;
        width: 386px;
        // height: 400px;
      }
      // .left-content{
      //   margin: 0 auto;
      // }
      .left,.right{
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-items: center;
      }
      .right-content{
        width: 300px;
        // height: 350px;
      }
      .login-content{
        // width: 830px;
        // height: 430px;
        height: 100%;
        .left{
          img{
            height: 100%;
          }
        }
        .right{
          // height: 100%;
          .content{
            // width: 300px;
            margin: 0 auto;
            .logo{
              padding-bottom: 40px;
              padding-top: 10px;
              img{
                margin: 0 auto;
                display: block;
              }
            }
            .username{
              padding-bottom: 16px;
            }
            ::v-deep .el-form-item__error{
              padding-left: 30px;
              padding-top: 3px;
            }
            .el-form-item{
              ::v-deep input{
                height: 30px;
              }
            }
            .read{
              font-size: 10px;
              color: rgb(192, 192, 192);
            }
            ::v-deep .el-input__inner{
              border: none;
              border-bottom: 1px solid rgb(179, 179, 179);
              width: 300px;
            }
            .button{
              width: 100%;
              background-color: rgb(15, 95, 218);
              margin-top: 30px;
            }
          }
        }
      }
    }
    .bottom-wrapper{
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgb(238, 238, 238);
    }
  }

</style>
