<template>
  <div class="bottom">
      <el-row class="content">
          <!-- 华锐同拓 -->
          <!-- <el-col :span="24" v-if="co == 'hyx'">
              <el-row>
                <el-col :span="24">湖南华锐同拓网络科技发展有限公司<span class="line">|</span><span>弘云星智慧企业管理系统</span></el-col>
            </el-row>
            <el-row>
                <el-col :span="24">Copyrigth ©2016-2020 版权所有
                    <span class="line">|</span><a href="http://beian.miit.gov.cn/" target="_blank">湘 ICP 备18020354号</a>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">技术服务，不良信息举报电话：0731-89720845</el-col>
            </el-row>
          </el-col> -->
          <!-- 星辰科技 -->
          <!-- <el-col :span="24" v-else>
              <el-row>
                <el-col :span="24">深圳市星辰计算机科技有限公司<span class="line">|</span><span v-html="wxMerchantName"></span></el-col>
            </el-row>
            <el-row>
                <el-col :span="24">Copyrigth ©2016-2020 版权所有
                    <span class="line">|</span><a href="http://beian.miit.gov.cn/" target="_blank">粤 ICP 备16089431号</a>
                    <span class="line">|</span>
                    <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030302001519" target="_blank">
                        <img class="icon" src="../../assets/img/bottom_icon.png" width="14px" height="14px"/>粤公网安备44030302001519号
                    </a>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">技术服务，不良信息举报电话：0755-25609945</el-col>
            </el-row>
          </el-col> -->
          <el-col :span="24">
              <el-row>
                <el-col :span="24">{{ wxMerchantName.systemName }}<span class="line">|</span><span> {{ wxMerchantName.systemType }}</span></el-col>
            </el-row>
            <el-row>
                <el-col :span="24">Copyrigth ©2016-2020 版权所有
                    <span class="line">|</span><a href="http://beian.miit.gov.cn/" target="_blank">{{ wxMerchantName.systemIcp }}</a>
                    <span class="line">|</span>
                    <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030302001519" target="_blank">
                        <img class="icon" src="../../assets/img/bottom_icon.png" width="14px" height="14px"/>{{ wxMerchantName.systemRecord }}
                    </a>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">技术服务，不良信息举报电话：{{ wxMerchantName.systemPhone }}</el-col>
            </el-row>
          </el-col>
      </el-row>
  </div>
</template>

<script>
export default {
    props: {
        wxMerchantName: Object
    },
    data() {
      return {
        co: sessionStorage.getItem('co')
      }
    }
}
</script>

<style lang="scss" scoped>
a{ 
text-decoration:none; 
color:#333; 
}
.bottom{
    height: 82px;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: rgb(49, 59, 76);
    display: flex;
    justify-content: center;
    align-items: center;
    .line{
        padding: 0 12px;
        color: black;
    }
    .content{
      ::v-deep.el-row {
        margin-top: 6px;
        .icon{
          width: 14px;
          height: 14px;
          display: inline-block;
          vertical-align: bottom;
          margin-right: 2px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      ::v-deep.el-row:nth-of-type(1) {
        margin-top: 0;
      }
    }
}
</style>